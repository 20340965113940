import React, { useEffect, useState } from "react";

// Libraries
import { Link, useNavigate } from "react-router-dom";

// Contexts
import { useAnalyticsData } from "../../../contexts/AnalyticsData/AnalyticsProvider";
import { Collection, Creator } from "../../../models/DataContext";
import { useToast } from "../../../contexts/Toast/Toast";

// Utilities
import {
  abridgeId,
  onlyCreatorCollections,
  paginate,
} from "../../../utilities/dataHelpers";

// Shared
import Container from "../../shared/Container";
import LoadingWrapper from "../../shared/LoadingWrapper";
import PaginationControls from "../../shared/PaginationControls";

// Models
import { PaginatedProps } from "../Home/PaginatedCreators";
import { usePage } from "../../../utilities/hooks";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";

interface CollectionsProps extends PaginatedProps {
  creator?: Creator | undefined;
}

const Collections: React.FC<CollectionsProps> = ({
  pageSize = 20,
  startingPage = 1,
  creator,
}) => {
  const { collections, collectionsLoaded } = useAnalyticsData();
  const { data, loading, error } = collections;
  const [sort, setSort] = useState<"name" | "itemsCount">("name");
  const [sortOrder, setSortOrder] = useState<boolean>(true);

  const sortHandler = (sortBy: typeof sort) => {
    if (sort == sortBy) {
      return setSortOrder(!sortOrder);
    }
    setSort(sortBy);
    setSortOrder(true);
  };

  const currentCollections = () => {
    if (collectionsLoaded && !collections.loading && !error) {
      if (data.collections) {
        if (creator) {
          return onlyCreatorCollections(
            data.collections,
            creator.avatars[0].userId
          );
        }
        return data.collections;
      }
    }
    return [];
  };

  const sortedCollections = [...currentCollections()].sort((a: any, b: any) => {
    if (typeof a[sort] == "string") {
      if (sortOrder)
        return b[sort].toUpperCase().trim() < a[sort].toUpperCase().trim()
          ? -1
          : 1;
      return a[sort].toUpperCase().trim() < b[sort].toUpperCase().trim()
        ? -1
        : 1;
    }
    if (sortOrder) return b[sort] - a[sort];
    return a[sort] - b[sort];
  });

  const { currentPage, totalPages, changePage } = usePage(
    pageSize,
    sortedCollections.length,
    startingPage
  );

  const paginatedCollections = paginate(
    sortedCollections,
    pageSize,
    currentPage
  );

  const SortArrows = ({ sortBy }: { sortBy: typeof sort }): JSX.Element => {
    return sort != sortBy ? (
      <TiArrowUnsorted style={{ display: "inline" }} />
    ) : sortOrder ? (
      <TiArrowSortedDown
        style={{
          display: "inline",
        }}
      />
    ) : (
      <TiArrowSortedUp
        style={{
          display: "inline",
        }}
      />
    );
  };

  if (error) {
    return (
      <Container
        direction="col"
        items="center"
        title="Creator Sales Data"
        width="1"
      >
        <div>Collection Not Found Found</div>
      </Container>
    );
  }

  return (
    <LoadingWrapper loading={loading}>
      <Container width="1" items="center" title="Collections" direction="col">
        {paginatedCollections ? (
          <>
            <table className="w-full border rounded-sm mt-2 table-auto">
              <thead>
                <tr className="border font-bold">
                  <th
                    style={{
                      width: "45%",
                      color: sort == "name" ? "rgb(255 63 85 / 1)" : undefined,
                    }}
                    onClick={() => sortHandler("name")}
                  >
                    Collection Name <SortArrows sortBy="name" />
                  </th>
                  {/* <th>Creator</th> */}
                  <th
                    onClick={() => sortHandler("itemsCount")}
                    style={{
                      color:
                        sort == "itemsCount" ? "rgb(255 63 85 / 1)" : undefined,
                    }}
                  >
                    Items <SortArrows sortBy="itemsCount" />
                  </th>
                  <th className="py-3" style={{ width: "45%" }}>
                    ID:
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedCollections.map(
                  (collection: Collection, i: number) => {
                    if (i < pageSize) {
                      return <CollectionData key={i} collection={collection} />;
                    }
                    return <></>;
                  }
                )}
                <td></td>
                <PaginationControls
                  totalPages={totalPages}
                  changePage={changePage}
                  currentPage={currentPage}
                />
              </tbody>
            </table>
          </>
        ) : (
          <></>
        )}
      </Container>
    </LoadingWrapper>
  );
};

export default Collections;

const CollectionData: React.FC<CollectionDataProps> = ({ collection }) => {
  const { getCreator } = useAnalyticsData();

  const navigate = useNavigate();

  const [collectionCreator, setCollectionCreator] = useState<Creator | null>(
    null
  );

  const { newToastNotification } = useToast();

  const getCollectionCreator = async (creatorId: string) => {
    const creator = await getCreator(creatorId);

    setCollectionCreator(creator);
  };

  useEffect(() => {
    if (!collectionCreator) {
      getCollectionCreator(collection.creator);
    }
  });

  useEffect(() => {
    if (
      collectionCreator &&
      collectionCreator?.avatars[0]?.userId !== collection.creator
    ) {
      getCollectionCreator(collection.creator);
    }
  }, [collection.creator]);

  return (
    <tr
      onClick={() => navigate(`/collections/${collection.id}`)}
      className={`w-full odd:bg-gray-50 hover:bg-red-50 cursor-pointer`}
    >
      <td
        className="border-t items-center py-3 px-10 justify-between flex flex-row min-h-[60px]"
        style={{ width: "45" }}
      >
        <p className="text-center w-full">{collection.name}</p>
      </td>
      <td className="border-t text-center align-middle">
        <p className="text-center w-full">{collection.itemsCount}</p>
      </td>
      <td
        onClick={() => newToastNotification("copied")}
        className="border-t text-center align-middle"
      >
        <Link
          className="text-[#FF3F55] underline"
          to={`/collections/${collection.id}`}
        >
          {abridgeId(collection.id, 30)}
        </Link>
      </td>
    </tr>
  );
};

const sortOptions = [
  { value: "creator", title: "Creator" },
  { value: "items", title: "Items" },
  { value: "collection", title: "Collection Name" },
];

type CollectionDataProps = {
  collection: Collection;
};
